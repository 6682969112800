import {BudgetBookEntry, BudgetBookQueryResponse} from "../data/BudgetBookEntry";
import Rest from "./Rest";
import RecurringTransactionEntry from "../data/RecurringTransactionEntry";
import DateHelper from "../global/DateHelper";
import {TransactionItem} from "../data/bankingapi/BankingApiModel";

export default class BudgetBookApi {

    public static async query(query: string, from: string, to: string, selectedCategoryId: string | undefined): Promise<BudgetBookQueryResponse> {
        return Rest.getSpecific(`/budget-book?query=${query}&from=${from}&to=${to}&categoryId=${selectedCategoryId}`);
    }

    public static async get(id: string): Promise<BudgetBookEntry> {
        return Rest.getSpecific(`/budget-book/${id}`);
    }

    public static async put(id: string, entry: BudgetBookEntry): Promise<BudgetBookEntry> {
        return Rest.put(entry, `/budget-book/${id}`);
    }

    public static async post(entry: BudgetBookEntry): Promise<BudgetBookEntry> {
        return Rest.post(entry, `/budget-book`);
    }

    public static async delete(id: string) {
        return Rest.delete(`/budget-book/${id}`);
    }

    public static async addFromRecurring(entry: RecurringTransactionEntry): Promise<BudgetBookEntry> {
        const convertedEntry = {
            id: '',
            designation: entry.designation,
            date: DateHelper.nowAtDay(entry.executionDay) || "",
            amount: entry.amount,
            monthlyAmount: entry.amount,
            category: entry.category,
            description: entry.description,
            files: []
        };

        return BudgetBookApi.post(convertedEntry);
    }

    private static getBankingEntryDesignation(value: TransactionItem): string {
        return `Institution: ${value.institution?.name}\nTransaktionsinfos: ${value.remittanceInformationUnstructured}`;
    }
}